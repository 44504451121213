import React, { Component } from 'react'
import { getCompanies } from '../../actions/AdminActions';
import { connect } from 'react-redux';
import { getAssesmentsCoach } from '../../actions/CoachAction';
import { changeStateValue } from '../../redux/MainReducer';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

export class AdminReportCoachPage extends Component {
    state={
        coachData:[],
        page:0,
        companies:[]
    }

    getCoach(company){
        this.props.changeStateValue({name:"loader", value:true})
        this.props.getAssesmentsCoach(company?.id)
        .then(resp=>{
            this.props.changeStateValue({name:"loader", value:false})
            let allData = [...this.state.coachData];
            allData.push({
                company: company,
                stats: resp
            })
            if(this.state.page+1 <= this.state.companies.length){
                this.setState({page: this.state.page+1, coachData: allData})
                setTimeout(()=>{
                    this.getCoach(this.state.companies[this.state.page])
                }, 20000)
            }
           
            // 
        })
    }
    componentDidMount(){
        this.props.getCompanies(5, '')
        .then(resp=>{
            let companies = resp.filter(x=> x.id != 32 && x.id != 34  &&  x.id != 35 &&  x?.id != 29 && x?.id != 28 && x?.id != 27 && x?.id != 26 && x?.id != 22 && x?.id != 25 && x?.id != 24 && x?.id != 19 && x?.id != 18 && x?.id != 31 && x?.id != 17);
            this.getCoach(companies[this.state.page])
            this.setState({companies: companies})
        })
    }
    getPercentage(data){
        let total = data.length;
        let error = data.filter(x=>x.read_status == 1)?.length;
        let success = data.filter(x=>x.read_status == 0)?.length;
        return {
            errors: Math.round((error*100) / total),
            success: Math.round((success*100) / total)
        }
    }
  render() {
    const {coachData, companies} = this.state;
    // console.log(coachData)
    // console.log(companies)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Assessment Coach" />
            <div className='grid-full-320  items-start  rounded-[20px] mt-[10px] mt-[42px]'>
                {
                    [...coachData]?.sort((a,b)=> this.getPercentage(b?.stats)?.success - this.getPercentage(a?.stats)?.success)?.map((data, i) => {
                        return (
                            <Link target='_blank' to={`/audit-company/${data?.company?.id}/${data?.company?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                <h3 className='text-center txt-blue text-[18px]'>{data?.company?.name}</h3>
                                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                                    <div style={{ width: `${this.getPercentage(data?.stats)?.errors}%`}} className='h-[30px] min-w-[30px] rounded-l-[10px] pl-[10px] bg-[#fd2e63] flex items-center justify-end '>
                                        <span className='text-[12px] text-white'>{this.getPercentage(data?.stats)?.errors}%</span>
                                    </div>
                                    <div style={{ width: `${this.getPercentage(data?.stats)?.success}%`,}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                                        <span className='text-[12px] text-white'>{this.getPercentage(data?.stats)?.success}%</span>
                                    </div>
                                </div>  
                            </Link>
                        )
                    })
                }
               
            </div>
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminCompanies: state.Data.adminCompanies
});
const mapDispatchToProps = {getCompanies, getAssesmentsCoach, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminReportCoachPage)
