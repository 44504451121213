import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';

export class FreeText extends Component {
  componentDidMount(){
    const input = document.querySelectorAll(`.free_text_input_random_${this.props.answers?.id}`);
    // console.log(input)
    if(input){
      input.forEach((x, i)=>{
        x.addEventListener('input', (e)=>{
          const value = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "").replaceAll(',','').
                   replaceAll('.','').replaceAll('?','').replaceAll('!','').replaceAll('+','').
                   replaceAll('-','').replaceAll('{','').replaceAll('}','').replaceAll('(','').
                   replaceAll(')','').replaceAll('[','').replaceAll(']','').replaceAll(':','').
                   replaceAll(';','').replaceAll('"','').replaceAll("'",'').replaceAll('|','').
                   replaceAll('/','').replaceAll('_','').replaceAll('İ', 'I').replaceAll('i', 'i').toLowerCase().trim();
            // console.log(value)
            this.props.changeStateValue({
              name:`examsData.${this.props.index}.questions.${this.props.questionIndex}.answers.${+e.target.getAttribute('aria-label')}.given_answer`,
              value: value
            })
        })
      })
    }
  }
  render() {
    const {answers, index} = this.props
    let arr = []
    let ques = []
    for(let i = 0; i < answers.description.split('}}}').length; i++){
        if(answers.description.split('}}}')[i].includes('{{{')){
            
            arr.push(`${answers.description.split('}}}')[i].split('{{{')[0]} <input type="text" id='${answers.id}' style=' min-width: 100px; max-width: 500px' value='' aria-label='${i}' class="free_text_input_random_${answers?.id} border border-[2px] border-black rounded-[5px] h-[25px]"/> |`)
            ques.push(answers.description.split('}}}')[i].split('{{{')[1].split('|'))
        }else{
            arr.push(answers.description.split('}}}')[i])
        }
        
    }
    const str = String(arr).replaceAll('|,', '');
    // console.log(str)
    return (
      <div>
         <div className='free_text_container leading-[30px]' dangerouslySetInnerHTML={{ __html:str }}></div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(FreeText)
