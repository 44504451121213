import React, { Component } from 'react'
import ExamRightSvg from '../../../components/svg/ExamRightSvg'
import Countdown, {zeroPad} from 'react-countdown';
import { mixinAlert } from '../../../actions/SweetAlerts';

export class ExamHeader extends Component {
  renderer( { hours, minutes, seconds, completed}){
      
    if(!completed){
        if(hours === 0 && minutes === 2 && seconds === 0 && window.location.pathname.split('/').includes('start-pooling-exams')){
            mixinAlert("info", "You have 2 minutes left. Please submit your exam. After 2 minutes, the system will lock the exam and you will get 0 score. Nothing will be saved. Please submit now")
        }
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }else{
        return <span className='txt-blue text-[20px] font-bold'>00:00:00</span>
    }
}

stopTimer(){
    if( window.location.pathname.split('/').includes('start-pooling-exams')){
        // this.props.navigate("/exam-results");
        this.props.onClickRight("submit", "not_time")
        // this.submitExamData(this.state.examId)
    }
}
  render() {
    const {onClickRight=()=>{}, timer="", minute, title, btnNext=false, btnDisable=false} = this.props;
    // console.log(btnNext)
    return (
      <div className='bg-white h-[100px] border shadow flex items-center justify-between pl-[20px] pr-[20px] fixed w-[calc(100%_-_53px)] max-[1100px]:w-[calc(100%_-_54px)]'>
        <h3 className='font-bold txt-blue text-[28px]'>{title}</h3>
        {
            timer != ''?
            <span className='txt-blue text-[20px] font-bold'>Time: {` `}
                <Countdown date={new Date(timer).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                    onComplete={this.stopTimer.bind(this)}>
                </Countdown>
            </span>:<span className='txt-blue text-[20px] font-bold'>00:00:00</span>
        }
        {
          btnNext?
          <button disabled={btnDisable} onClick={()=>onClickRight("submit")} className='bg-blue text-white h-[35px] pl-[9px] pr-[9px] rounded-[7px] '>
              Submit
          </button>:
          <button disabled={btnDisable} onClick={()=>onClickRight("not_submit")} className='bg-gray-400 w-[40px] h-[40px] inline-flex items-center justify-center rounded-full'>
            <ExamRightSvg />
        </button>
        }
        
      </div>
    )
  }
}

export default ExamHeader
