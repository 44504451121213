import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { mixinAlert } from '../../actions/SweetAlerts';
import { withHooks } from '../../actions/withHooks';
import { insertNewData } from '../../actions/MainAction';

export class ExamStartAuthModal extends Component {
  state={
    btnDisabled:false
  }
    componentDidMount(){
        // window.addEventListener("beforeunload", ()=>{
        //     localStorage.setItem("xxx", btoa(this.props.authCodeModal?.code))
        // })
        // this.props.changeStateValue({
        //     name:"authCodeModal.code",
        //     value: atob(localStorage.getItem("xxx"))
        // })
        // localStorage.removeItem("xxx")
    }

    closeModal=()=>{
      this.props.changeStateValue({
        name:"authCodeModal",
        value:{
          position:false,
          code:'',
          existsCode:'',
          exam_id:''
        }
      })
    }
    checkCode=(authCodeModal)=>{
      if(authCodeModal?.code !="" && authCodeModal?.code?.length ==6){
        this.props.insertNewData(`check-exam-key-valid/${authCodeModal?.data?.id}`, {code: authCodeModal?.code})
        .then(resp=>{
          if(resp == "success"){
            this.props.insertNewData("exam-auth-key", {exam_id:authCodeModal?.exam_id, code: authCodeModal?.code})
            .then(resp=>{
              if(resp=="success"){
                this.props.insertNewData(`exam-used-code/${authCodeModal?.data?.id}`, {})
                .then(resp2=>{
                  if(resp2=="success"){
                    if(authCodeModal?.data?.exam?.exam_type == "normal"){
                      this.props.navigate(`/exam-start/${authCodeModal?.exam_id}`)
                    }else{
                      this.props.navigate(`/start-pooling-exams/${authCodeModal?.exam_id}`)
                    }
                  
                    this.props.changeStateValue({
                      name:"authCodeModal.position",
                      value:false
                    })
                  }else{
                    mixinAlert("error", "Something went wrong")
                  }
                })
              
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }else{
            mixinAlert("warning", "Exam key expired")
          }
        })
      }else{
        mixinAlert("warning", "Exam key is not correct!")
      }
    }
  render() {
    const {authCodeModal} = this.props;
    const {btnDisabled} = this.state;
    // console.log(authCodeModal)
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white p-[20px] rounded-[10px] w-[300px]'>
            <input type='password' className='bg-gray-100 w-full h-[35px] rounded-[10px] pl-[5px]' placeholder='Exam key' value={authCodeModal?.code} onChange={(e)=>this.props.changeStateValue({name:'authCodeModal.code', value: e.target.value})} />
            <div className='flex mt-[20px] items-center justify-between'>
                <button disabled={btnDisabled} onClick={this.closeModal.bind(this)}  className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
                <button onClick={this.checkCode.bind(this, authCodeModal)} disabled={btnDisabled} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    authCodeModal: state.Data.authCodeModal
});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamStartAuthModal))
