import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withHooks } from '../actions/withHooks'
import { getExceptionCoach } from '../actions/CoachAction';
import { Link } from 'react-router-dom';

export class ExceptionCoach extends Component {
    state={
        loader:true
    }
    componentDidMount(){
        this.props.getExceptionCoach(this.props.params?.id)
        .then(resp=>{
            this.setState({loader:false})
        })
    }
    getPercentage=(data)=>{
        let error = Math.round((data?.stats*100) / data?.count);
        let success = 100 - error;
        return {
            errors: error,
            success: success
        }
    }
  render() {
    const {exceptionCoach, lang, user} = this.props;
    const {loader} = this.state;
    // console.log(exceptionCoach)
    return (
        <div className={` w-full `}>
            <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                <div className='flex justify-between items-center'>
                    <h2>Exception tracking</h2>
                    <div className='flex gap-[20px] items-center'>
                        <Link to={user?.role == "company_head" || user?.role == "office_manager"?`company-exam-exception`:''} target='_blank' className='bg-blue p-[4px_7px] rounded-[6px] text-white text-[12px] font-semibold'>{lang?.view}</Link>
                    </div>
                </div>
                {
                loader?
                <div  className={`skeleton skeleton-loading `}>
                    <h4 className={`skeleton-description h-[30px_!important]`}></h4>
                </div>:
                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center '>
                    
                    <div style={{ width: `${this.getPercentage(exceptionCoach)?.errors}%`}} className='h-[30px] rounded-l-[10px] w-[20%] bg-[#fd2e63] flex items-center justify-end pr-[10px]'>
                        <span className='text-[14px] text-white'>{this.getPercentage(exceptionCoach)?.errors}%</span>
                    </div>
                    <div style={{ width: `${this.getPercentage(exceptionCoach)?.success}%`}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                        <span className='text-[14px] text-white'>{this.getPercentage(exceptionCoach)?.success}%</span>
                    </div>
                </div>  
            }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    exceptionCoach: state.Data.exceptionCoach,
    lang: state.Data.lang,
    user: state.Data.user,
})
const mapDispatchToProps = {getExceptionCoach}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExceptionCoach))
