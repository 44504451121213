import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getTeacherCoach } from '../../actions/CoachAction';
import CoachPartCard from '../../components/dashboard/CoachPartCard';
import EmptySvg from '../../components/svg/EmptySvg';
import SkeletonCard from '../../components/cards/SkeletonCard';
import { getCoachStatus } from '../../actions/getRole';
import CompanyWeeklyCoach from '../../components/CompanyWeeklyCoach';
import AssesmentCoach from '../../components/AssesmentCoach';
export class TeacherDashboard extends Component {
    state={
        loader:true
    }
    componentDidMount(){
        this.props.getTeacherCoach("auto")
        .then(resp=>{this.setState({loader:false})})
    }
    getManuallyCoach=()=>{
        this.setState({loader:true})
        this.props.getTeacherCoach("manual")
        .then(resp=>{this.setState({loader:false})})
    }
    getCoachStatsAll(companyCoach){
        let arr = [];
        companyCoach.forEach((item=>{
            for(const x of item?.statistics){
                let stats = [];
                if(x?.exam !=""){
                    stats.push({
                        ...x.exam,
                        title:"Exam",
                        type:'exam'
                    })
                }
                if(x?.assessments !=""){
                    stats.push({
                        ...x.assessments,
                        title:"RP.",
                        type:'assessments'
                    })
                }
                if(x?.essay !=""){
                    stats.push({
                        ...x.essay,
                        title:"Es.",
                        type:'essay'
                    })
                }
                if(x?.speaking !=""){
                    stats.push({
                        ...x.speaking,
                        title:"Sp.",
                        type:'speaking'
                    })
                }
                if(x?.homework !=""){
                    stats.push({
                        ...x.homework,
                        title:"PT",
                        type:'pt'
                    })
                }
                if(x?.speaking_practice !=""){
                    stats.push({
                        ...x.speaking_practice,
                        title:"Sp. P",
                        type:'sp_practice'
                    })
                }
               
                let info={
                    course_title: x.course_title,
                    lesson_mode: x.lesson_mode,
                    student_name: item?.full_name,
                    teacher_name: x?.teacher?.full_name,
                    student_id:item?.id,
                    statistics: stats,
                    // status:x?.exam?.status?.status
                    status: getCoachStatus(x),
                }

                arr.push(info)
            }
        }))
        return arr
    }
  
    getPercentage(companyCoach){
        let errors =  this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length;
        let warnings = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length;
        let success = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length;
        let total = errors + warnings + success;
        return {
            total: total,
            errors: Math.round((errors*100)/total),
            warnings: Math.round((warnings*100)/total),
            success: Math.round((success*100)/total),
        }
    }      
  render() {
    const {teacherCoach, lang} = this.props;
    const {loader} = this.state;
    return (
        <div className='p-[20px] flex flex-col gap-[20px]'>
        <CompanyWeeklyCoach padding="" />
        <AssesmentCoach padding="" />
        {   loader?
            <div className=' gap-[30px]  max-[1100px]:mt-[120px] max-[800px]:mt-[150px] items-start p-[20px] rounded-[20px]'>
                <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <div className='grid-full-320 '>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                    </div>
                </div>
            </div>:
            
             this.getCoachStatsAll(teacherCoach).length !==0?
            <div className=' gap-[30px]  max-[1100px]:mt-[120px] max-[800px]:mt-[150px] items-start  rounded-[20px]'>
                 
                <div className='bg-white p-[20px] rounded-[20px] mb-[20px]'>
                    <div className='flex justify-between items-center'>
                        <h2>{lang?.coach_tracker}</h2>
                        <button className='bg-blue p-[4px_7px] rounded-[6px] text-white text-[12px] font-semibold' onClick={this.getManuallyCoach.bind(this)}>{lang?.coach_refresh_btn}</button>
                   </div>
                    <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                        <div style={{ width: `${this.getPercentage(teacherCoach)?.errors}%`}} className='h-[30px] rounded-l-[10px] w-[20%] bg-[#fd2e63] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(teacherCoach)?.errors}%</span>
                        </div>
                        <div style={{ width: `${this.getPercentage(teacherCoach)?.warnings}%`}} className='h-[30px]  w-[20%] bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(teacherCoach)?.warnings}%</span>
                        </div>
                        <div style={{ width: `${this.getPercentage(teacherCoach)?.success}%`}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(teacherCoach)?.success}%</span>
                        </div>
                    </div>  
               </div>
               <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_mustbe_done}</h2>
                    {
                        this.getCoachStatsAll(teacherCoach).filter(a=>a.status == 2).length!=0?
                        <div className='grid-full-320 '>
                            {
                                    this.getCoachStatsAll(teacherCoach).filter(a=>a.status == 2)?.map((data, i)=>{
                                        return(
                                            <CoachPartCard key={i} data={data?.statistics} student_id={data?.student_id} student_name={data?.student_name} course_title={data?.course_title}  status={data?.status}  />
                                        )
                                    })
                                }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                    
               </div>
               <div className=' bg-white p-[20px] mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_need_to_be_done}</h2>
                    {
                        this.getCoachStatsAll(teacherCoach).filter(a=>a.status == 1).length!=0?
                        <div className='grid-full-320'>
                        {
                            this.getCoachStatsAll(teacherCoach).filter(a=>a.status == 1)?.map((data, i)=>{
                                return(
                                    <CoachPartCard key={i} data={data?.statistics} student_id={data?.student_id} student_name={data?.student_name} course_title={data?.course_title}  status={data?.status}  />
                                )
                            })
                        }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                   
               </div>
               <div className=' bg-white p-[20px]  mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_already_done}</h2>
                    <div className='grid-full-320'>
                    {
                        this.getCoachStatsAll(teacherCoach).filter(a=>a.status == 0)?.map((data, i)=>{
                            return(
                                <CoachPartCard key={i} data={data?.statistics} student_id={data?.student_id} student_name={data?.student_name} course_title={data?.course_title}  status={data?.status}  />
                            )
                        })
                    }
                    </div>
               </div>
                {/* {
                    this.getCoachStats(teacherCoach)?.map((data, i)=>{
                        return(
                            <CoachPartCard key={i} student_name={data?.student_name} course_title={data?.course_title} title={`${data?.title}`} status={data?.status?.status} msg={data?.status?.msg} />
                        )
                    })
                } */}
            </div>:
            <div className='w-full h-[623px] bg-white rounded-[12px] flex justify-center items-center pl-[20px] pr-[20px]'>
                <div className='flex justify-center items-center flex-col'>
                
                    <EmptySvg />
                    <h3 className='text-[24px] txt-blue font-bold mt-[15px] text-center'>No data available</h3>
                
                </div>
            </div>
        }
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({
    teacherCoach: state.Data.teacherCoach,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getTeacherCoach}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboard)


