import React, { Component } from 'react'
import Header from '../components/header/Header'
import StudentDashboard from './student_role/StudentDashboard'
import { connect } from 'react-redux'
import AdminExams from './admin/AdminExams';
import EmptySvg from '../components/svg/EmptySvg';
import TeacherDashboard from './teacher_role/TeacherDashboard';
import CompanyDashboard from './company_head_role/CompanyDashboard';
import AccountantCompanies from './accountant_role/AccountantCompanies';
import AuditorDashboard from './auditor_role/AuditorDashboard';
import SupportMain from './support_role/SupportMain';
import { getEndStats } from '../actions/AdminActions';

import XLSX from "sheetjs-style"
import * as FileSaver from "file-saver"
import moment from 'moment';
export class Dashboard extends Component {
  componentDidMount(){
    window.scrollTo(0,0);
    // this.props.getEndStats()
  }

  exportEx(endStatExcelData){
    const fileType = "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let datas = [];
    endStatExcelData.forEach(element => {
      datas.push({
        "Company": element?.name,
        "All Students": element?.all,
        "Failed students": element?.failed,
        "After passed": element?.passed_after
      })
    });
    const ws = XLSX.utils.json_to_sheet(datas);
    const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type:'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, `Students-${moment(new Date()).format("DD-MM-YYYY")}`+fileExtension)
  }
  render() {
    const {user, endStatExcelData, dashTitle} = this.props;
    // console.log(endStatExcelData)
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title={dashTitle} />
        <div className='mt-[42px] flex gap-[31px] flex-col'>
          {
            user ===""?
            null:
            user?.role === "student"?
            <StudentDashboard />:
            user?.role === "teacher" || user?.role === "head_teacher"?
            <TeacherDashboard />:
            user?.role === "office_manager" || user?.role === "company_head"?
            <CompanyDashboard />:
            user?.role === "accountant"?
            <AccountantCompanies />:
            user?.role === "auditor"?
            <AuditorDashboard />:
            user?.role === "content_manager"?
            <AdminExams />:
          
            user?.role == "parent" || 
            user?.role == "teacher" || 
            user?.role == "head_teacher" || 
            user?.role == "speaking_teacher" ?
            <div className='w-full h-[623px] bg-white rounded-[12px] flex justify-center items-center pl-[20px] pr-[20px]'>
              <div className='flex justify-center items-center flex-col'>
                
                  <EmptySvg />
                  <h3 className='text-[24px] txt-blue font-bold mt-[15px] text-center'>The CELT coach coming soon</h3>
                 
              </div>
            </div>:null
          }
          {/* {
            user?.role == "super_admin"?
            <button onClick={this.exportEx.bind(this, endStatExcelData)}>Export</button>:null
          } */}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  endStatExcelData: state.Data.endStatExcelData,
  dashTitle: state.Data.dashTitle,
});
const mapDispatchToProps = {getEndStats}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
