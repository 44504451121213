import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAssesmentsCoach } from '../actions/CoachAction';
import { Link } from 'react-router-dom';
import { withHooks } from '../actions/withHooks';

export class AssesmentCoach extends Component {
    state={
        loader:true
    }
    componentDidMount(){
        this.props.getAssesmentsCoach(this.props.params?.id)
        .then(resp=>{
            this.setState({loader:false})
        })
    }

    getPercentage(data){
        let total = data.length;
        let error = data.filter(x=>x.read_status == 1)?.length;
        let success = data.filter(x=>x.read_status == 0)?.length;
        return {
            errors: Math.round((error*100) / total),
            success: Math.round((success*100) / total)
        }
    }
  render() {
    const {assessmentCoach, lang, user} = this.props;
    const {loader} = this.state;
    // console.log(this.getPercentage(assessmentCoach))
    // console.log(assessmentCoach)
    return (
        <div className={` w-full `}>
            {
                // assessmentCoach.length != 0?
                <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <div className='flex justify-between items-center'>
                        <h2>Parent tracking</h2>
                        <div className='flex gap-[20px] items-center'>
                            <Link to={user?.role == "super_admin"?`/assessment-coach/${this.props.params?.id}`:`/assessment-coach/`} target='_blank' className='bg-blue p-[4px_7px] rounded-[6px] text-white text-[12px] font-semibold'>{lang?.view}</Link>
                        </div>
                    </div>
                    {
                    loader?
                    <div  className={`skeleton skeleton-loading `}>
                        <h4 className={`skeleton-description h-[30px_!important]`}></h4>
                    </div>:
                    <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center '>
                        
                        <div style={{ width: `${this.getPercentage(assessmentCoach)?.errors}%`}} className='h-[30px] rounded-l-[10px] w-[20%] bg-[#fd2e63] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(assessmentCoach)?.errors}%</span>
                        </div>
                        <div style={{ width: `${this.getPercentage(assessmentCoach)?.success}%`}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(assessmentCoach)?.success}%</span>
                        </div>
                    </div>  
                }
                </div>
                // :null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    assessmentCoach: state.Data.assessmentCoach,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getAssesmentsCoach}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AssesmentCoach))
