import React, { Component } from 'react'
import { getAllExceptionCoach } from '../../actions/CoachAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';

export class AdminAllExceptionCoach extends Component {

    componentDidMount(){
        this.props.getAllExceptionCoach();
    }

    getPercentage=(data)=>{
        let error = Math.round((data?.stats*100) / data?.count);
        let success = 100 - error;
        return {
            errors: error,
            success: success
        }
    }
  render() {
    const {allExceptionCoach} = this.props;
    // console.log(allExceptionCoach)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Exceptions Coach" />
            <div className='grid-full-320  items-start  rounded-[20px] mt-[10px] mt-[42px]'>
                {
                    [...allExceptionCoach]?.filter(x=> x.id != 32 && x.id != 34 && x.id != 35 &&  x?.id != 29 && x?.id != 28 && x?.id != 27 && x?.id != 26 && x?.id != 22 && x?.id != 25 && x?.id != 24 && x?.id != 19 && x?.id != 18 && x?.id != 31 && x?.id != 17)?.sort((a,b)=> this.getPercentage(b?.coach)?.success - this.getPercentage(a?.coach)?.success)?.map((data, i) => {
                        return (
                            <Link target='_blank' to={`/audit-company/${data?.id}/${data?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                <h3 className='text-center txt-blue text-[18px]'>{data?.name}</h3>
                                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                                    <div style={{ width: `${this.getPercentage(data?.coach)?.errors}%`}} className='h-[30px] min-w-[30px] rounded-l-[10px] pl-[10px] bg-[#fd2e63] flex items-center justify-end '>
                                        <span className='text-[12px] text-white'>{this.getPercentage(data?.coach)?.errors}%</span>
                                    </div>
                                    <div style={{ width: `${this.getPercentage(data?.coach)?.success}%`,}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                                        <span className='text-[12px] text-white'>{this.getPercentage(data?.coach)?.success}%</span>
                                    </div>
                                </div>  
                            </Link>
                        )
                    })
                }
            
            </div>
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    allExceptionCoach: state.Data.allExceptionCoach
});
const mapDispatchToProps = {getAllExceptionCoach}
export default connect(mapStateToProps, mapDispatchToProps)(AdminAllExceptionCoach)
