import React, { Component } from 'react'
import {jwtDecode} from "jwt-decode";
import { getExamId, getExamStart, insertNewData } from '../../../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import SingleExamParent from './SingleExamParent';
import MultiExamParent from './MultiExamParent';
import ExamHeader from './ExamHeader';
import Header from '../../../components/header/Header';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { changeStateValue } from '../../../redux/MainReducer';
import LoadingPage from './LoadingPage';

export class StartPoolingExam extends Component {
  state={
    currentPage:0,
    title:"",
    examId:'',
    btnDisable:false,
    timer:'',
    minute:'',
    loadPage:true
  }

  getExams(){
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.props.getExamStart(this.props.params.id, data.sub)
      .then(resp=>{
        this.setState({loadPage:false});
      
      })
    }
  }
  componentDidMount(){
    this.props.changeStateValue({name:"sideBarVisible", value:false})
    this.getExams();
    this.props.changeStateValue({name:'examsData', value:[]})
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.props.getExamId(this.props.params.id, data.sub)
      .then(resp=>{
        // console.log(resp)
        if(resp==="error"){
            this.props.navigate("/")
        }else{
            this.setState({title: resp?.exam_title, examId: resp?.id, timer: resp?.updated_at, minute: resp?.exam?.duration_minutes})
        }
        
      })
    }
    
  }
 
  onClickRight=(status, finish_status="")=>{
   
        const questions = this.getFilterData()[0]?.questions?this.getFilterData()[0]?.questions:[];
        let total_count = 0;
        let answer_count = 0;
    
        questions.forEach(question=>{
          if(question?.question_type == "single_choice"){
            total_count+=1;
            if(question?.given_answer){
              answer_count+=1;
            }
          }else if(question?.question_type == "multiple_choice"){
            total_count+=1;
            if(question?.given_answer && question?.given_answer?.length !=0){
              answer_count+=1;
            }
          }else if(question?.question_type == "free_text" || question?.question_type == "drop_down"){
            question?.answers?.forEach(answer=>{
              total_count+=1;
              if(answer?.given_answer){
                answer_count+=1;
              }
            })
          }
        })
        if(total_count == answer_count || status == "submit"){
          if(finish_status == ""){
            confirmAlert("warning", "Are you sure?", "You won’t be able to come back to this section if you move on. Do you want to continue?", "Yes, Continue", "","No, Go Back")
            .then(respAlert=>{
              if(respAlert == "success"){
                this.setState({loadPage:true, btnDisable:true})
                let questions = JSON.stringify(this.getFilterData()[0]?.questions?this.getFilterData()[0]?.questions:[]);
                this.props.insertNewData("pooling-exam-answer-save", {questions: questions, submit_status:status, studentExam_id: this.state.examId, exam_id: this.props.params.id})
                .then(resp=>{
                  this.setState({btnDisable:false})
                  if(resp == "success"){
                    if(status == "submit"){
                      this.props.changeStateValue({name:"sideBarVisible", value:true})
                      this.props.navigate("/exam-results")
                     
                    }else{
                      this.getExams();

                    }
                    
                  }else{
                    mixinAlert("error", "Something went wrong");
                  }
                })
              }
            })
          }else{
            this.setState({loadPage:true, btnDisable:true})
            let questions = JSON.stringify(this.getFilterData()[0]?.questions?this.getFilterData()[0]?.questions:[]);
            this.props.insertNewData("pooling-exam-answer-save", {questions: questions, submit_status:status, studentExam_id: this.state.examId, exam_id: this.props.params.id})
            .then(resp=>{
              this.setState({btnDisable:false})
              if(resp == "success"){
                if(status == "submit"){
                  this.props.changeStateValue({name:"sideBarVisible", value:true})
                  this.props.navigate("/exam-results")
                }else{
                  this.getExams();

                }
                
              }else{
                mixinAlert("error", "Something went wrong");
              }
            })
          }
          
        }else{
          mixinAlert("info", "Please answer all the questions to continue");
        }
  }
  getFilterData(){
    return this.props.examsData?.filter(x=>x.full_answer_status == 0)
  }
  getFilterIndex(){
    return this.props.examsData?.filter(x=>x.full_answer_status == 1)?.length
  }
  componentWillUnmount(){
    this.props.changeStateValue({name:"sideBarVisible", value:true})
  }

  exitExamBtn=()=>{
    confirmAlert("warning", "Are you sure?", "You will be exit the exam.","Yes, Exit.", "", "No, Go Back.")
    .then(resp=>{
      if(resp == "success"){
        this.props.changeStateValue({name:"sideBarVisible", value:true})
        this.props.navigate("/exams");
      }
    })
  }
  render() {
    const {examsData} = this.props;
    const {currentPage, timer, minute, title, loadPage, btnDisable} = this.state;
    return (
      <div className='w-full pl-[27px] pr-[27px]  relative w-full pt-[1px] exam_parent'>
        <ExamHeader btnDisable={btnDisable} title={title} timer={timer} minute={minute} btnNext={this.getFilterIndex()+1 == examsData.length} onClickRight={this.onClickRight.bind(this)} />
        <div className='mt-[100px]'>
        {
          !loadPage?
          this.getFilterData()?.map((data, i)=>{
            if(i==0){
              return(
                <MultiExamParent key={i} data={data} index={this.getFilterIndex()} />
              )
            }
          }):<LoadingPage />
        }
        <button onClick={this.exitExamBtn.bind(this)} className='absolute mt-[20px] bg-blue text-white text-[14px] font-semibold p-[4px_9px] rounded-[8px]'>Exit exam</button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  examsData: state.Data.examsData
});
const mapDispatchToProps = {getExamStart, insertNewData, getExamId, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StartPoolingExam))
