import React, { Component } from 'react'
import Description from './Description';
import Title from './Title';
import Image from './Image';
import Audio from './Audio';
import SingleChoice from './SingleChoice';
import FreeText from './FreeText';
import MultipleChoice from './MultipleChoice';
import DropDown from './DropDown';

export class MultiExamParent extends Component {
  state={
    freeTextCode:false
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.index != this.props.index){
      this.setState({freeTextCode:true});
      setTimeout(()=>{
        this.setState({freeTextCode:false});
      }, 50)
    }
   
  }
  componentDidMount(){
    setTimeout(()=>{
      try{
        if(typeof window?.MathJax !== "undefined"){
          window.MathJax.typeset();
        }else{
        }
       }catch(err){
       
       }
     },100);
  }

  addSpaceToHtml(text){
    return text?.replaceAll("<p>", "  <p>  ")?.replaceAll("<br>", "  <br>  ")?.replaceAll("<span>", "  <span>  ")?.replaceAll("<b>", "  <b>  ")?.replaceAll("<div>", "  <div>  ")?.replaceAll("<i>", "  <i>  ")?.replaceAll("<strong>", "  <strong>  ")
  }
  render() {
    const {data, index} = this.props;
    const {freeTextCode} = this.state;
    // console.log(data)

    return (
      <div className='bg-white p-[20px] shadow border grid grid-cols-2 h-[calc(100vh_-_170px)] '>
        <div className='flex w-full h-[calc(100vh_-_200px)] overflow-auto pr-[20px] flex-col gap-[20px] border-r border-r-[2px] scroll-r'>
            {
                data?.title && data?.title !="Task"?
                <Title text={data?.title} />:null
            }
            {
                data?.image?
                <Image url={data?.image_full_url} />:null
            }
            {
                data?.audio?
                <Audio url={data?.audio_full_url} />:null
            }
            {
                data?.description?
                <Description text={this.addSpaceToHtml(data?.description)} />:null
            }
        </div>
        <div className='border-l border-l-[2px] pl-[20px] pr-[20px]  h-[calc(100vh_-_200px)] overflow-auto scroll-r'>
          {
            data?.questions.map((item, i)=>{
              return (
                <div key={i} className='flex w-full  flex-col gap-[20px] overflow-auto'>
                  {
                    item?.title?
                    <Title text={item?.title} />:null
                  }
                  {
                    item?.image?
                    <Image url={item?.image_full_url} />:null
                  }
                  {
                    item?.audio?
                    <Audio url={item?.audio_full_url} />:null
                  }
                  {  item?.question_type !== "free_text" ?
                    item?.description?
                    <Description text={this.addSpaceToHtml(item?.description)} />:null:
                    item?.question_type == "free_text" && !freeTextCode ?
                    <FreeText index={index} answers={item} questionIndex={i} />:
                    item?.question_type == "drop_down"?
                    <DropDown index={index} answers={item} questionIndex={i} />:null
                  }
                  <div>
                    {
                      item?.question_type == "single_choice"?
                      <SingleChoice item={item} index={index} id={item?.id} questionIndex={i} answers={item?.answers} />:
                      item?.question_type == "multiple_choice"? 
                      <MultipleChoice item={item} index={index} id={item?.id} questionIndex={i} answers={item?.answers} />:null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default MultiExamParent
