import React, { Component } from 'react'
import { bigAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { connect } from 'react-redux';
import { insertNewData, insertNewDataV2 } from '../../../actions/MainAction';
export class UserModalFooter extends Component {
    state={
        btnDisabled:false
    }
    
    saveBtn(data){
        let permission = true;
        for(const property in data){
            const check = data.unRequireds.includes(property);
            if(!check){
             
                if(data[property] === ""){
                    permission = false;
                    mixinAlert("info", this.props.lang?.fill_req_inputs)
                }

                if(data?.id === "" && data["password"]){
                    if(!this.props.passwordPermission){
                        permission = false;
                        mixinAlert("info", this.props.lang?.min_8_words)
                    }
                }else if(data?.id !== "" && data["password"]){
                    if(!this.props.passwordPermission){
                        permission = false;
                        mixinAlert("info", this.props.lang?.min_8_words)
                    }
                }
            }
        }
        if(data["email"] && !data?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            mixinAlert("warning", 'Email should be valid format')
            return
        }
        if(permission){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property]);
                }
                
            }
            let url = data?.url;
            if(data?.id && data?.id !== ""){
                url = `${data?.url}/${data?.id}`;
                formData.append("_method", "put");
            }
            if(data?.childs || data?.childs?.length==0){
                let childs = [];
                for(const item of data?.childs){childs.push(item?.value)};
                formData.append("child", childs);
            }
            if(data?.title_input){
                formData.append("title", data?.title_input);
            }
            if(data?.monthly_date){
                formData.append("date", data?.monthly_date);
            }
         
            if(data?.version && data?.version === "v2"){
                this.props.insertNewDataV2(url, formData)
                .then(resp=>{
                    this.setState({btnDisabled:false})
                    data.afterCallFunction(...data?.funcParams)
                    if(resp === "success"){
                        bigAlert(data?.id && data?.id !== ""? "Edited!": "Added!", `Data ${data?.id && data?.id !== ""? "edited": "added"} successfully.`, "success")
                        this.props.closeBtn()
                    }else{
                        bigAlert("Error!","Something went wrong.", "error")
                    }
                })
            }else{
                this.props.insertNewData(url, formData)
                .then(resp=>{
                    this.setState({btnDisabled:false})
                    data.afterCallFunction(...data?.funcParams)
                    if(resp === "success"){
                        bigAlert(data?.id && data?.id !== ""? "Edited!": "Added!", `Data ${data?.id && data?.id !== ""? "edited": "added"} successfully.`, "success")
                        this.props.closeBtn()
                    }else{
                        bigAlert("Error!", data?.errorMsg?data?.errorMsg: "Something went wrong.", "error")
                    }
                })
            }
            
        }
    }
  render() {
    const {closeBtn=()=>{}, data, lang} = this.props;
    const {btnDisabled} = this.state;
    return (
        <div className='flex mt-[20px] items-center justify-between'>
            <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-red h-[25px]  pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
            
            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px]  pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{data?.id === ""? lang?.add:lang?.update}</button>
           
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang,
    passwordPermission: state.Data.passwordPermission,
});
const mapDispatchToProps = {insertNewData, insertNewDataV2}
export default connect(mapStateToProps, mapDispatchToProps)(UserModalFooter)
