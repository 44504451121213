import moment from "moment";
import { engLang } from "../lang";

export const initialState = {
    progressLoading:{
        position:false,
        text:'',
        complete:false
    },
    spTimer:"00:00",
    passwordPermission: true,
    navBtn:true,
    lang: engLang,
    loader: false,
    loaderLong: false,
    user: '',
    sideBarVisible:true,
    homeworks:[],
    essayReviews:[],
    speakingReviews:[],
    examResults:[],
    hwResults:[],
    listenings:[],
    studentCourses:[],
    coursesData:'',
    speakingPracticeReviews:[],
    ialExReviews:[],
    certificates:[],
    resourcesData:[],
    speakings:[],
    lessons:[],
    essays:[],
    spPractice:[],
    officeSpPractice:[],
    ialExams:[],
    checkins:[],
    notAllowData:{
        position: false,
        type:''
    },
    errorPageData:{
        position:false,
        code:''
    },
    partScoreModal: {
        position: false,
        title: '',
        data: []
    },
    mainModal:{
        position:false
    },
    ialExamsData:'',
    examsData:[],
    freetextExamAnswers:[],
    exams:[],
    toeflWritingExam:'',
    studentToeflSpeakingExam:'',

    parentStudents:[],
    studentCheckIns:[],


    essayUngraded:[],
    speakingUngraded:[],
    staffEssay:[],
    staffSpeaking:[],

    staffExamResults:[],
    teachers:[],
    parents:[],
    officeManagers:[],
    userModalData:{
        position:false
    },
    officeAllStudents:[],
    officeStudents:[],
    reviewModalData:{
        position:false
    },

    lessonModes:[],
    editStudentParams:{
        id:'',
        first_name:'',
        last_name:'',
        phone_number:'',
        password:'',
        email:'',
        date_of_birth:'',
        class:'',
        payment_Reminder_max_value:'',
        payment_Reminder_min_value:'',
        school_year:'',
        send_email_status:'false',
        avatar_full_url:'',
        role: "student"
    },
    teacherStats:[],
    companyWiseReports:[],
    assesmentsUngraded:[],
    teacherSalary:{
        bonus:0,
        check_ins:[]
    },
    teacherEnrollModalData:{
        position: false,
        id:'',
        company_id:'',
        check_in:[],
        group_id:'',
        group_name:'',
        lesson_hour:'',
        lesson_mode:'',
        lesson_mode_id:'',
        student_id:'',
        study_mode:'',
        teacher_id:'',
        exists:false
    },
    addExistsGroup:{
        count:'',
        file:null,
        image:null,
        file_full_url:null,
        image_full_url:null,
        note:'',
        fee:''
    },
    studentNottes:[],
    enrollPaymentsModal:[],
    groupsByTeacher:[],
    teacherBonuses:[],
    officeManagerBonuses:[],
    officeToeflExams:[],
    toeflModalData:{
        position:false
    },
    bonuses:[],
    officePaymentDue:[],
    speakingTeacherCheckins:[],
    teacherOwnSalary:{bonus:0, check_ins:[]},
    officeEnrollStudents:'',
    typeOfStudents:'',
    officeExamTakens: '',
    officeTeacherManagerData:'',
    officePublicApplicants:[],
    studentExamReviews:[],
    speakingByStudent:[],
    speakingTeacherCheckinsView:[],
    chatUsers:[],
    groupUsers:[],
    msgAllUsers:[],
    msgBoxData:{
        count:0,
        messages:[]
    },
    msgBoxInfo:'',
    messageFileSend:{
        position:false,
        file:'',
        preview:'',
        type:'',
        id:'',
        url:'',
        replied_id:''
    },
    msgGroupUsers:[],
    unreadMsgCount:[],
    essayPlagiarism:{
        position: false,
        value:''
    },
    studentContractsModal:{
        file:null,
        image:null
    },
    teacherContractsModal:{
        position:false,
        file:null,
        image:null,
        date:moment(new Date()).format("YYYY-MM"),
        teacher_id:''
    },
    adminPrompt:[],



    adminIalExamsData:[],
    adminSpPractice:[],
    adminEssays:[],
    adminSpeakings:[],
    adminExams:[],
    adminModalData:{position: false},

    adminExamData: {
        id: '',
        title: '',
        course_id: '',
        lesson_id: '',
        duration_minutes: '',
        retake_minutes: '',
        retake_time: '',
        points: '',
        exam_image: null,
        type: '0',
        tooltip:'',
        exam_type:'normal'

    },
    adminExamModalData: {
        position: false,
        id: '',
        title: '',
        audio_file: null,
        description: '<p></p>',
        question_image: null,
        question_description: '',
        score: '',
        sub_type: '',
        type: '',
        question_option: '[]',
        video_file: null,
        video_link: null,
        exam_id: '',
        roboType:false,
        descriptionPosition: false
    },
    previousAnswers: {
        position: false,
        id: '',
        title: '',
        audio_file: null,
        description: '<p></p>',
        question_image: null,
        question_description: '',
        score: '',
        sub_type: '',
        type: '',
        question_option: '[]',
        video_file: null,
        video_link: null,
        exam_id: ''
    },
    adminExamQuestions:[],
    modalRoboQuestion: {
        position: false,
        parentDesscription:'',
        parentId: ''
    },
    modalRoboPrev:{
        questions:[]
    },
    answerCount:0,

    
    adminImages:[],
    adminExceptions:[],
    examExceptionModalData: {
        position:false,
        image: null,
        student_id:'',
        status:'0'
    },
    coachExceptionModal: {
        position:false,
        image: null,
        student_id:'',
        status:'0',
        count:''
    },
    adminCoachExceptions:[],
    companyWeeklyCoach:[],
    examException:'',
    coachException:'',
    adminExamSettings:[],
    examSettingModal:{
        position: false,
        params: []
    },
    adminCompanies:[],
    adminManagers:[],
    adminGroups:[],
    adminAllocations:[],
    companyNotAssignedCourses:[],
    adminLessons:[],
    adminLessonModes:[],
    adminExamResults:[],
    adminCourseRules:[],
    adminUnlockStudents:[],
    notAssignedEnrolls:[],
    notAssignedCompanies:[],
    selectedCompanies:[],
    teacherAllChecks:[],

    enrollPayments:[],
    addPaymentModal:{
        position:false,
        enroll_id:'',
        count:'',
        file:null,
        file_full_url:null,
        image:null,
        image_full_url:null
    },
    // coach
    studentCoach:[],
    teacherCoach:[],
    companyCoach:[],
    adminStaff:[],
    auditCoach:[],
    adminWeeklyCompanies:[],
    // public
    acceptedStudents:[],
    publicUser:'',
    levelCheckExam:'',
    examQuestions:'',

    staffExResults:[],
    addExResultsData:{
        position:false,
        id:'',
        student_name:'',
        exam_type_id:'',
        score:'',
        date:'',
        image:'',
        cert_image:'',
        image_full_url:'',
        cert_image_full_url:'',
        modalTitle:''
    },
    publicExamTypes:[],
    officeApplies:[],

    allCountries:[],
    eduDegree:[],
    disciplines:[],
    examSchedule:[],
    selectSchedule:{
        user_id:"",
        date:"",
        time:"",
        course_id:"",
        times:[],
        modalPosition: false
    },
    examScheduleDays:[],
    supportExamSchedule:[],
    oficeExamSchedule:[],
    loginLogs:[],
// celtgo
    celtgoInOuts:[],
    celtgoWorkerInOuts:[],


    supportMessages:[],
    supportMsgFile:{
        position:false,
        file:'',
        message:'',
        preview:"",
        type:"",
        reply_id:""
    },
    privateMsgModal:{
        position: false
    },
    examAuthCode:'',
    authCodeModal:{
        position:false,
        code:'',
        existsCode:'',
        exam_id:''
    },
    examKeys:[],
    speakingByLesson:[],
    auditWeeklyCoachOne:[],

    poolingExamParentData:{
        id:'',
        parent_id:'',
        category_id:'',
        sub_category_id:'',
        title:'',
        instruction:'',
        description:'',
        question_type:'',
        type:'',
        level:'',
        audio:'',
        video:'',
        image:'',
        score:'',
    },

    examCategories:[],
    examSubCategories:[],
    poolingExamQuestions:[],
    poolingExamQuestionData:{
        position:false,
        id:'',
        parent_id:'',
        category_id:'',
        sub_category_id:'',
        title:'',
        instruction:'',
        description:'',
        question_type:'',
        type:'',
        level:'',
        audio:'',
        video:'',
        image:'',
        score:'',
        answer_options:[]
    },
    poolingExamAutoAdd:{
        position:false,
        id:'',
        parent_id:'',
        category_id:'',
        sub_category_id:'',
        title:'',
        instruction:'',
        description:'',
        question_type:'',
        type:'',
        level:'',
        audio:'',
        video:'',
        image:'',
        score:'',
        answer_options:[],
    },
    randevuPdfModal:{
        position:false,
        name:'',
        center:'',
        date:'',
        time:''
    },
    endOfCourseData:[],
    endOfExamExceptions:[],
    failedStudentsData:[],
    endOfExceptModal:{
        image:'',
        course_id:'',
        image_full_url:''
    },
    courseRequireds:[],
    studentAllCourses:[],
    troubleStudents:[],
    examSectionBySubCategories:[],

    poolingFullParentModal:{
        position:false,
        id:'',
        parent_id:'',
        category_id:'',
        sub_category_id:'',
        section_id:'',
        title:'',
        description:'',
        question_type:'',
        audio:'',
        video:'',
        image:'',
        audio_full_url:'',
        video_full_url:'',
        image_full_url:'',
    },
    studentCalculatorModal:{
        teacher_id:'',
        page:'teacher',
        student_group_id:'',
        course_id:'',
        lesson_mode:'',
        study_mode:'',
        lesson_mode_id:'',
        lesson_hour:'',
        group_type:'',
        fee:'',
        count:'',
        note:'',
        image:'',
        file:'',
        file_full_url:'',
        image_full_url:'',
        week_day:'',
        price_view:false,
        selected_count:'',
        prev_price_view:false,
        price_type:'new'
    },
    adminPriceCalculator:[],
    studentGroupParams:'',
    studentEnrollPrices:[],
    studentGroupUsers:[],
    onlineAppliesData:[],

    poolingExams:[],
    examPoolingModaldata:{
        position:false
    },
    newApplyCheck:0,

    oldestEnrollCount:{
        id:'',
        count:0,
        fee:0,
        image:'',
        file:'',
        image_full_url:'',
        file_full_url:'',
    },

    firstFailedCoach:[],
    seniorModalData:{
        position: false,
        id:'',
        teacher_id:'',
        file:'',
        file_full_url:'',
        file_type:''
    },
    adminTeacherSeniorRequests:[],
    examPermissionModalData:{
        position:false
    },
    waitingSupportResponse: false,
    studentCoursesAll:[],
    failedStModal:{
        student_id:'',
        course_id:'',
        image:'',
        status:'',
        type:'exception',
        image_full_url:''
    },
    loginBlocksData:[],
    managerCompanies:[],

    createGroupModal:{
        position:false,
    },
    createGroupMessageModal:{
        position:false,
    },
    groupsLinks:[],
    studentWeeklyCoach:[],
    assessmentCoach:[],
    endStatExcelData:[],
    dashTitle:'dashboard',
    exceptionCoach:{
        count:0,
        stats:0
    },
    allExceptionCoach:[]





}
